<template>
  <div class="tabs-container">
    <div
      v-for="(e, i) in options"
      :key="i"
      :class="['tab-container', { action: e[replaceFields.type] == value }]"
      @click="$emit('set:value', e[replaceFields.type])"
    >
      <img
        :src="
          require(`@/assets/screen/bj/icon_${
            e[replaceFields.type] == '01' ? 'untreated' : 'processed'
          }.png`)
        "
        :alt="e[replaceFields.lable]"
      />
      <div style="display: flex; flex-direction: column; margin-left: 8px">
        <span
          :style="`font-size: 30px;font-family: DIN;font-weight: bold;color: ${
            e[replaceFields.type] == '01' ? '#FE1329' : '#1CC2FF'
          };`"
          >{{ e[replaceFields.value] }}</span
        >
        <span style="font-size: 14px; font-weight: 400; color: #ffffff">{{
          e[replaceFields.lable]
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //组件注册
  components: {},
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: [String, Number],
      default: 1,
    },
    options: {
      type: Array,
      default: () => [
        {
          type: 1,
          lable: '未处理预警',
          value: 85,
        },
        {
          type: 2,
          lable: '已处理预警',
          value: 148,
        },
      ],
    },
    replaceFields: {
      type: Object,
      default: () => ({
        type: 'type',
        lable: 'lable',
        value: 'value',
      }),
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.tabs-container {
  display: flex;
  border-bottom: 1px solid #175192;
  .tab-container {
    display: flex;
    flex: 1;
    padding-bottom: 8px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
  }
  .action {
    border-color: #2296f8;
    box-shadow: 0 0 0 transparent, 0 0 0 transparent,
      0 -14px 14px -10px #2296f8 inset, 0 0 0 transparent;
  }
}
</style>
